import request from '../utils/request'

export default {
    async handle(data) {    // 新增/修改信息
        return request('/v1/floor/handle', data, 'POST');
    },
    async getRoomByNumber(data) {    // 获取楼层信息
        return request('/v1/floor/getRoomByNumber', data, 'POST');
    },
    async getLocationIBeacon(data) {    // 获取楼层信息
        return request('/v1/data/iBeacon', data, 'POST');
    }
}
