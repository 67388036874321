<!--图片标记-->
<template>
  <div
      class="container"
      v-loading="isLoading"
      element-loading-text="数据加载中..."
      element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!--顶部工具栏-->
    <div class="top-tool">
      <!--左上角盒子-->
      <!--右上角盒子-->
      <div class="top-right-div">
        <el-tooltip
            class="item"
            effect="dark"
            content="返回"
            placement="bottom"
        >
          <el-button type="primary" class="clear-btn" @click="handleBack">
            返回
          </el-button>
        </el-tooltip>
      </div>
    </div>
    <!--画布盒子-->
    <div class="middle-div">
      <div class="canvas-div">
        <canvas id="myCanvas" width="1080" height="768"></canvas>
      </div>
    </div>
  </div>
</template>
<script>
import CanvasSelect from 'canvas-select'
import floor from "@/model/floor";

export default {
  props: {},
  components: {},
  data() {
    return {
      isLoading: false, //加载状态
      output: []
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.isLoading = true
    this.loadFloor()
  },
  methods: {
    async loadFloor() {
      const ret = await floor.getLocationIBeacon({
        imei: 863659048865439,
        start_time: '2023-12-21 00:00:00',
        end_time: '2023-12-21 23:59:59'
      })
      if (ret.code === 200) {
        console.log(ret.data[0])
        this.output = ret.data[0].map
      }
      this.initFloor()
    },
    initFloor() {
      this.instance = new CanvasSelect(
          "#myCanvas",
          'https://adminapi.yztc2022.com/map-bg.jpg'
      )
      this.instance.labelMaxLen = 10
      this.instance.readonly = true
      this.instance.strokeStyle = '#000000'
      // 图片加载完成
      this.instance.on("load", (src) => {
        console.log("image loaded", src)
      })
      this.instance.setData(this.output)
      this.isLoading = false
      // 删除
      this.instance.on("delete", () => {
        return false
      })
      // 选中
      this.instance.on("select", () => {
        return false
      })
      this.instance.on("updated", () => {
        return false
      })
    },
    handleBack() {
      window.history.back()
    }
  }
};
</script>
<style scoped lang="scss">
.container {
  user-select: none;
  height: 100%;
  width: 100%;

  .top-tool {
    height: 50px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(39, 55, 70, 1);

    .top-left-div {
      display: flex;

      .color-div-a,
      .color-div {
        border-radius: 50%;
        height: 40px;
        width: 40px;
        margin: 5px 10px;

        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 10px #fff;
        }
      }

      .color-div-a {
        box-shadow: 0px 0px 10px #fff;
      }

      .getcolor-div {
        position: relative;
        margin: 5px 10px;

        img {
          position: absolute;
          top: 0;
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }

        input {
          z-index: 10;
          border-radius: 50%;
          position: absolute;
          top: 0;
          background-color: transparent;
          height: 40px;
          width: 40px;
          opacity: 0;
          border: 0px;
          cursor: pointer;
        }
      }

      .el-form-item__label {
        color: #ffffff !important;
      }
    }

    .top-right-div {
      margin: 0px;
      display: flex;

      .model-btn,
      .model-btn-a {
        background-color: #455769;
        border: 0px;
        padding: 9px 15px;
        margin: auto 10px;

        img {
          height: 20px;
          width: 20px;
        }
      }

      .model-btn-a {
        border: 0px;
        background-color: rgba(255, 255, 255, 0.5);
      }

      .download-btn,
      .clear-btn {
        margin: auto 10px;
        //padding: auto;
      }

      .upload-btn {
        margin: auto 10px;
        height: 40px;
        width: 50px;
        padding: 0px;
        position: relative;

        input {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 10;
          width: 100%;
          height: 100%;
          opacity: 0;
          border: 0px;
        }
      }
    }
  }

  .middle-div {
    width: 100%;
    height: calc(100% - 50px);

    .canvas-div {
      width: 100%;
      height: 94%;
      overflow: hidden;
      position: absolute;
      background-color: #000000;

      #myCanvas {
        background-color: rgba(255, 255, 255);
        //position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 9%;
        margin-top: 7%;
      }
    }
  }

  .msg-div {
    position: absolute;
    z-index: 10;
    background-color: rgba(255, 255, 255, 0.5);
    color: #000;
    padding: 5px 20px;
  }
}
</style>
